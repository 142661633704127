import { filter, isEmpty } from 'lodash';

export default {
    getFocusedCompany(state) {
        return state.focusedCompany;
    },
    getFilteredContracts(state) {
        return state.filteredContracts;
    },
    availablePartenaires(state) {
        return filter(state.allConstPartenaires, (part) => {
            return state.linkModalInfo.rightLevel < 9020 ? !part.is_broadcaster : part.is_broadcaster;
        });
    },
    userHasLink(state) {
        return !isEmpty(state.userLinkPart);
    },
};
