export default {
    selectedKeys: 0, //Le bouton sélectioné du sider de gauche
    SelectedSubscriberIndex: 0, //L'index dans la tableau "displayedEtabs". Permet de savoir quel souscripteur est sélectionné parmi tous ceux affichés
    userProfil: null, //Les informations de l'utilisateur qui s'est connecté
    rights_level: 0, //Le niveau de droit de l'utilisateur qui s'est connecté
    displayedEtabs: [], //La liste des souscripteurs qui sont affichés à l'écran
    allSubscribers: [], //La liste de tous les souscripteurs.
    favoris: [], //La liste des souscripteurs que l'utilisateur à mis dans ses favoris
    allBroadcaster: [], //La liste de tous les diffuseurs qui existent
    arrets: [], //La liste de toutes les personnes qui sont en arrêt maladie du souscripteur
    listContacts: [], //La liste de tous les contacts du souscripteur
    listeDemandes: [], //La liste de toutes les demandes en cours du souscripteur.
    listeRibs: [], //La liste de tous les ribs/ibans du souscripteur
    listeContrats: [], //La liste de tous les contrats du souscripteur
    listeDocuments: [], //La liste de tous les documents du souscripteur
    filtersDoc: [], //La liste de toutes les options que l'utilisateur à choisi pour filtrer les documents du souscripteur
    filtersAdh: [], //La liste de toutes les options que l'utilisateur à choisi pour filtrer les adhérents du souscripteur
    filtersDues: [], //la liste de toutes les options que l'utilisateur à choisi pour filtrer les cotisations du souscripteur
    listeAdh: [], //La liste de tous les adhérents de l'utilisateur (l'onglet "Tous les adhérents")
    listeAccess: [], //La liste des personne dont on peut visualiser les accès
    selectedPkUser: null, //Le PK de l'utilisateur que l'on sélectionne. Utiliser quand on veut voir le profil d'un utilisateur ou qu'on l'on veut lui ouvrir une raison sociale
    documentsPart: [], //La liste des documents du diffuseur connecté
    broadcasterPK: 0, //Le PK du diffuseur sur lequel on veut voir les souscripteurs
    tabOnglet: 0, //L'onglet du <a-tab> sur le quel on est. Utiliser dans "Mon portefeuille" pour que l'on revienne au même endroit si l'on change de fenêtre puis que l'on revient dessus
    isRad: true,
    myDocsFilter: [],
    documentReferences: [],
    myDocumentYear: 'Tous',
    companies: [],
    companiesSelected: [],
    fetchingCompany: false,
    broadcasterSelected: {},
    companiesModalVisisble: false,
    focusedCompany: {
        adresse: '',
        adresse_comp1: '',
        adresse_comp2: '',
        email: '',
        tel: '',
        PK: -1,
        lib: '',
        dossier: '',
        libelle: '',
        stoppedPeople: [],
        contacts: [],
        demands: [],
        rib_iban: [],
        contracts: [],
        documents: [],
        members: [],
        cotisations: [],
    },
    contractsIndiv: [],
    cotisations: [],
    pendingCot: true,
    pendingAdh: true,
    cotisationsFIlterYear: 'Tous',
    FIlterYear: '',
    indemnisations: [],
    selectedContracts: [],
    filteredContracts: [],
    finishLoadContract: false,

    filterData: 0,
    actifData: true,
    searchObjectData: 'nom_prenom',
    searchTextData: '',
    data: [],
    usersToDisplay: [],
    current: 1,
    linkModalVisible: false,
    linkModalInfo: {},
    selectedPart: '',
    selectedPartToFetchPK: '',
    searchAllPartenaires: '',
    allPartenaires: [],
    allPartenairesLoading: false,
    allConstPartenaires: [],
    userLinkPart: [],
    depositModaleVisible: false,
};
