import axios from 'axios';
import constants from '../constants/constants.js';
import qs from 'qs';
import { filter } from 'lodash';
import router from '../../../route';
//import router from '../../../route/index'
export default {
    async save({ dispatch, state, commit, rootState }, redirect = true) {
        if (redirect) {
            commit('setSaveLoading', true);
        }
        const response = await axios.post(
            constants.url + `EXT/functions/inc-form_ij${state.isCollectif ? '' : '_terri'}.php`,
            qs.stringify({
                action: 'save_ij',
                user: rootState.broadcaster.selectedPkUser,
                inputs: JSON.stringify(state.inputs),
            }),
        );

        const files_to_save = filter(state.inputs.files, ['new', true]);
        for (let element of files_to_save) {
            delete element.status;
            delete element.error;
            delete element.response;
            delete element.percent;
            await axios.post(
                constants.url + `EXT/functions/inc-form_ij${state.isCollectif ? '' : '_terri'}.php`,
                qs.stringify({
                    action: 'upload_file',
                    user: rootState.broadcaster.selectedPkUser,
                    attachments: JSON.stringify(element),
                }),
            );
        }
        if (response.data.success && redirect) {
            router.push({ name: 'Wallet' });
            
            await dispatch(
                'get_demands',
                { PK: rootState.broadcaster.focusedCompany.PK },
                {
                    root: true,
                },
            );
            commit('setSaveLoading', false);
        }
        
        return response.data.success;
    },
    async getDocs() {
        const response = await axios.get(constants.url + 'EXT/functions/zone_echange.php', {
            params: {
                action: 'get_document',
            },
        });

        return response.data;
    },
    async removeFile({ state, commit }, id) {
        const response = await axios.post(
            constants.url + `EXT/functions/inc-form_ij${state.isCollectif ? '' : '_terri'}.php`,
            qs.stringify({
                action: 'suppr_doc',
                id_doc: id,
            }),
        );
        if (response.data.success) {
            commit('removeInputFile', response.data.uid);
        }

        return response.data;
    },
    async send_ij({ dispatch, state, rootState }) {
        await dispatch('save', false);
        const response_pdf = await axios.post(
            constants.url + `EXT/functions/inc-form_ij${state.isCollectif ? '' : '_terri'}.php`,
            qs.stringify({
                action: 'generate_pdf',
                user: rootState.broadcaster.selectedPkUser,
            }),
        );
        if (response_pdf.data.success) {
            const response = await axios.post(
                constants.url + `EXT/functions/inc-form_ij${state.isCollectif ? '' : '_terri'}.php`,
                qs.stringify({
                    action: 'envoi_mail',
                    user: rootState.broadcaster.selectedPkUser,
                    pk_part: rootState.broadcaster.userProfil.PK,
                }),
            );
            if (response.data.success) {
                await dispatch('get_demands', { PK: rootState.broadcaster.focusedCompany.PK }, { root: true });

                return true;
            }
        }

        return false;
    },
    async getInfos({ state, commit, rootState }) {
        commit('setLoading', true);
        const response = await axios.post(
            constants.url + `EXT/functions/inc-form_ij${state.isCollectif ? '' : '_terri'}.php`,
            qs.stringify({
                action: 'get_infos_ij',
                user: rootState.broadcaster.selectedPkUser,
            }),
        );
        const responsePJ = await axios.post(
            constants.url + `EXT/functions/inc-form_ij${state.isCollectif ? '' : '_terri'}.php`,
            qs.stringify({
                action: 'get_pj',
                user: rootState.broadcaster.selectedPkUser,
            }),
        );
        if (response.data.success) {
            commit('setInputValue', response.data.infos_ij);
            commit('setLoading', false);
        }
        if (responsePJ.data.success) {
            commit('setInputFiles', responsePJ.data.files);
            commit('setLoading', false);
        }
    },
};
