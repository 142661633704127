import VueRouter from 'vue-router';
import Vue from 'vue';

Vue.use(VueRouter);

const LoginScreen = () => import('../components/LoginScreen.vue');
const Accueil = () => import('../components/Home.vue');
const Board = () => import('../components/Profil/Board.vue');
const Wallet = () => import('../components/Profil/MyWallet.vue');
const AllMembers = () => import('../components/Profil/AllMembers/AllMembers.vue');
const Acces = () => import('../components/Profil/Acces.vue');
const MyProfil = () => import('../components/Profil/MyProfil.vue');
const DropOffDocs = () => import('../components/DropOffDocs.vue');
const DailyCompensation = () => import('../components/DailyCompensation/DailyCompensation.vue');
const SimpleProfil = () => import('../components/SimpleProfil.vue');
const MyDocs = () => import('../components/MyDocuments.vue');
const Cotisations = () => import('../components/Profil/Cotisations/Cotisations.vue');
const Indemnisations = () => import('../components/Profil/Indemnisations/Indemnisations.vue');
const Contract = () => import('../components/Profil/Contracts/Contracts.vue');
const library = () => import('../components/library.vue');

const routes = [
    {
        path: '',
        name: 'Home',
        component: LoginScreen,
    },
    {
        path: '/MonProfil',
        name: 'MyProfil',
        component: MyProfil,
    },
    {
        path: '/DropOffDocs',
        name: 'DepoDocs',
        component: DropOffDocs,
    },
    {
        path: '/DailyCompensation',
        name: 'DailyCompensation',
        component: DailyCompensation,
    },
    {
        path: '/SimpleProfil',
        name: 'ProfilSimple',
        component: SimpleProfil,
        props: true,
    },
    {
        path: '/Accueil',
        component: Accueil,
        children: [
            {
                path: '',
                name: 'Board',
                component: Board,
            },
            {
                path: '/Portefeuille',
                name: 'Wallet',
                component: Wallet,
            },
            {
                path: '/Adherent',
                name: 'AllMembers',
                component: AllMembers,
            },
            {
                path: '/Acces',
                name: 'Access',
                component: Acces,
            },
            {
                path: '/MesDocuments',
                name: 'MesDocuments',
                component: MyDocs,
            },
            {
                path: '/MesContrats',
                name: 'Contract',
                component: Contract,
            },
            {
                path: '/MesCotisations',
                name: 'Cotisations',
                component: Cotisations,
            },
            {
                path: '/MesIdemnisations',
                name: 'Indemnisations',
                component: Indemnisations,
            },
            {
                path: '/base_documentaire',
                name: 'lib',
                component: library,
            },
        ],
    },
];

const router = new VueRouter({
    routes,
});

// router.beforeEach((to, from, next) => {
//     let access_token = Vue.cookie.get("access_token");
//     if (to.name === 'Login' || access_token !== null) {
//       next({ name: "Home" });
//     } else {
//       next();
//     }
// });

export default router;
