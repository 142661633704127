export default {
    displayPopUpAccount: false,
    displayHelp: false,
    contactForm: false,
    userType: null,
    canUsurpate: false,
    contactAttachments: [],
    PKUser: null,
    userLinkPart: [],
    usurpKey: 0,
    controlMode: false,
    loadingScreen: false,
    carouselContentBackOffice: {},
    carouselContent: {},
    diffuseurDocs: [],
    baseDocsRoles: {},
    baseDocs: {},
    subCatDocBackOffice: [],
};
