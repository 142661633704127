import Vue from 'vue';
import router from './route/index';
import i18next from './i18n/';
import VueI18Next from '@panter/vue-i18next';
import App from './App.vue';
import store from './store/';

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import '@/sass/components.scss';

import 'beautify-scrollbar/dist/index.css';
import 'vue-loaders/dist/vue-loaders.css';
import VueLoaders from 'vue-loaders';
import V2LazyList from 'v2-lazy-list';
import VueMq from 'vue-mq';
import InlineSvg from 'vue-inline-svg';

import 'ax-design-system/dist/style.css';

Vue.use(Antd);
Vue.use(VueI18Next);
Vue.use(VueLoaders);
Vue.use(V2LazyList);
Vue.use(VueMq, {
    breakpoints: {
        sm: 450,
        md: 1250,
        lg: Infinity,
    },
    defaultBreakpoint: 'sm',
});

Vue.component('InlineSvg', InlineSvg);

const i18n = new VueI18Next(i18next);

async function importDesignSystem() {
    const AxxoneDesignSystem = await import('ax-design-system');
    Vue.use(AxxoneDesignSystem.install);
    mountApp();
}

async function mountApp() {
    new Vue({
        store,
        router,
        render: (h) => h(App),
        i18n,
    }).$mount('#app');
}

importDesignSystem();
