import Vue from 'vue';
import Vuex from 'vuex';
// import createPersistedState from "vuex-persistedstate";

Vue.directive('click-outside', {
    bind(el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
            if (!(el === event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    },
});

Vue.use(Vuex);
const store = new Vuex.Store({
    ...require('./modules/common/'),
    modules: {
        broadcaster: require('./modules/profil'),
        dailyCompensation: require('./modules/dailyCompensation'),
    },
    // plugins: [createPersistedState()]
});

export default store;
